import useAuthenticatedQuery from 'hooks/useAuthenticatedQuery';
import { UseQueryOptions } from 'react-query';
import { AdvisorFirm } from '~/components/Form/InvestorGroupsSearchSelect/queries/useInvestorGroups';
import userKeys from './user';

type Advisor = {
  id: string;
  advisor_email: string;
  first_name: string;
  full_name: string;
  gf_staff_type: '' | 'Customer Service';
  last_name: string;
  role: string;
  advisor_firm: AdvisorFirm;
  external_execution_permission: 'DISPLAY_SHOW' | 'DISPLAY_HIDE';
  is_read_only: boolean;
};

export type User = {
  name: string;
  email: string;
  role: string;
  picture: string;
  advisor?: Advisor;
  is_read_only: boolean;
};

export default function useUser({
  staleTime = 60 * 60 * 1000,
  ...rest
}: UseQueryOptions<User> = {}) {
  return useAuthenticatedQuery<User>({
    queryKey: userKeys.all,
    options: {
      staleTime,
      ...rest,
    },
  });
}
